import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import axios from 'axios';
import qs from 'qs';
import { API_ROOT, logApiError } from '@/store/api';
import { PRODUCTION, VUEX_KEY, debug } from './constants';

import account from '@/store/account';
import admin from '@/store/admin';
import ai from '@/store/ai';
import configuration from '@/store/configuration';
import content from '@/store/content';
import guided_planning from '@/store/guided_planning';
import onboarding from '@/store/onboarding';
import marketing from '@/store/marketing';
import media from '@/store/media';
import model from '@/store/model';
import notifications from '@/store/notifications';
import notifier from '@/store/notifier';
import questionnaire from './questionnaire';
import reports from '@/store/reports';
import scenarios from '@/store/scenarios';
import service from '@/store/service';
import subscriptions from '@/store/subscriptions';
import transition_animation from '@/store/transition_animation';

import { logError } from '@/constants';
import { trackEvent } from '@/mixpanel';

Vue.use(Vuex);

const vuexLocalStorage = new VuexPersistence({
    key: VUEX_KEY,
    storage: window.localStorage,
    reducer: state => ({
        auth: state.auth,
        extraHeaders: state.extraHeaders,
        impersonatedUser: state.impersonatedUser,
        admin: {
            headers: state.admin.headers,
        },
        configuration: {
            availableProducts: state.configuration.availableProducts,
        },
        guided_planning: {
            guidedPlanningAnswers: state.guided_planning.guidedPlanningAnswers,
            guidedPlanningGraph: state.guided_planning.guidedPlanningGraph,
            guidedPlanningInfo: state.guided_planning.guidedPlanningInfo,
            guidedPlanningProgress: state.guided_planning.guidedPlanningProgress,
        },
        marketing: {
            advisorProfile: state.marketing.advisorProfile,
            advisoryPartnerProfile: state.marketing.advisoryPartnerProfile,
            marketingEvents: state.marketing.marketingEvents,
        },
        model: {
            crutReportData: state.model.crutReportData,
            gratModelForm: state.model.gratModelForm,
            iraModelForm: state.model.iraModelForm,
            modelLength: state.model.crutModelLength,
            modelStrategy: state.model.crutModelStrategy,
            oilGasModelForm: state.model.oilGasModelForm,
            ppliModelForm: state.model.ppliModelForm,
            qsbsModelForm: state.model.qsbsModelForm,
            solarModelForm: state.model.solarModelForm,
            totalOrdinaryIncome: state.model.totalOrdinaryIncome,
            totalLtcgIncome: state.model.totalLtcgIncome,
        },
        notifications: {
            notificationsData: state.notifications.notificationsData,
        },
        onboarding: {
            onboardingInfo: state.onboarding.onboardingInfo,
            onboardings: state.onboarding.onboardings,
            preSelectedSolarProject: state.onboarding.preSelectedSolarProject,
            savedSolarProjects: state.onboarding.savedSolarProjects,
        },
        questionnaire: {
            oilGasInfo: state.questionnaire.oilGasInfo,
            riskAnalysisInfo: state.questionnaire.riskAnalysisInfo,
        },
    }),
});


const store = new Vuex.Store({
    strict: debug,
    plugins: [vuexLocalStorage.plugin],
    modules: {
        account,
        admin,
        ai,
        configuration,
        content,
        guided_planning,
        marketing,
        media,
        model,
        notifications,
        notifier,
        onboarding,
        questionnaire,
        reports,
        scenarios,
        service,
        subscriptions,
        transition_animation,
    },
    state: {
        auth: {},
        impersonatedUser: null,
        filters: {},
        extraHeaders: {},
        loading: false,
        loadingText: '',
    },
    getters: {
        isAdvisor: state => {
            return (state.impersonatedUser || state.auth.user || {}).is_advisor || false;
        },
        isImpersonating: state => {
            return Boolean(state.extraHeaders['X-Impersonate']);
        },
        isLoggedIn: state => {
            return Boolean((state.auth || {}).access || '');
        },
        user: state => {
            return state.impersonatedUser || state.auth.user || {};
        },
        axiosConfig: (state) => {
            const headers = {
                'Authorization': `Bearer ${state.auth.access}`,
                'X-Requested-With': 'XMLHttpRequest',
                ...state.extraHeaders,
            };
            return {
                headers,
                transformResponse(resp) {
                    let data = resp;
                    if (typeof resp === 'string') {
                        try {
                            data = JSON.parse(resp);
                        } catch (e) { /* Ignore */ }
                    }
                    if ((data.error || '').indexOf('password expired') !== -1 && data.url) {
                        window.location = data.url;
                    }
                    return data;
                },
                paramsSerializer: function (params) {
                    return qs.stringify(params, { arrayFormat: 'repeat' });
                },
            };
        },
    },
    mutations: {
        updateImpersonatedUser(state, user) {
            state.impersonatedUser = user;
        },
        updateAuth(state, jwt) {
            const { user = {} } = jwt;
            state.auth = { ...state.auth, ...jwt };

            try {
                if (PRODUCTION) {
                    this._vm.$intercom.update({
                        user_id: user.id,
                        name: `${user.first_name} {${user.last_name}}`,
                        email: user.email,
                    });
                }
            } catch (e) {
                logError(e);
            }
        },
        updateFilters(state, filters) {
            state.filters = { ...filters };
        },
        updateExtraHeaders(state, headers) {
            state.extraHeaders = { ...headers };
        },
        updateLoading(state, val) {
            state.loading = val;
        },
        updateLoadingText(state, text) {
            state.loadingText = text;
        },
    },
    actions: {
        async auth({ commit, dispatch }, payload) {
            try {
                const { data } = await axios.post(
                    `${API_ROOT}/token/`,
                    payload,
                );
                commit('updateAuth', data);
            } catch (e) {
                logApiError(e, dispatch);
                throw e;
            }
        },
        async refreshJWT({ commit, dispatch, state }) {
            try {
                const refresh = state.auth.refresh;
                const user = state.auth.user;
                if (!refresh) {
                    return dispatch('clean_auth');
                }
                const { data } = await axios.post(
                    `${API_ROOT}/token/refresh/`,
                    { refresh, user },
                );
                commit('updateAuth', data);
            } catch (e) {
                logApiError(e, dispatch);
            }
        },
        async clean_auth({ commit }) {
            commit('updateAuth', { access: '', refresh: '', user: {} });
        },
        async logout({ commit, dispatch, getters }) {
            if (getters.isLoggedIn && getters.isImpersonating) {
                dispatch('admin/stopImpersonate');
            }
            commit('updateAuth', { access: '', refresh: '', user: {} });
            commit('onboarding/updateOnboardings', []);
            commit('marketing/updateAdvisorProfile', {});
            commit('marketing/updateAdvisoryPartnerProfile', {});
            commit('questionnaire/updateOilGasInfo', {});
            commit('questionnaire/updateRiskAnalysisInfo', {});
        },
        async register({ dispatch }, payload) {
            const method = payload.method || 'post';
            payload = {
                username: payload.email,
                ...payload,
            };
            try {
                const { data } = await axios[method](
                    `${API_ROOT}/registration/register/`,
                    payload,
                );
                trackEvent('Account created', { username: payload.email });
                return data;
            } catch (e) {
                logApiError(e, dispatch);
                throw e;
            }
        },
        async activate({ commit, dispatch }, payload) {
            try {
                const { data } = await axios.get(
                    `${API_ROOT}/registration/activate/${payload.params.uid}/${payload.params.token}/`,
                );
                trackEvent('Account activated');
                commit('updateAuth', data);
            } catch (e) {
                logApiError(e, dispatch);
                throw e;
            }
        },
        async sendResetPassword({ dispatch }, payload) {
            try {
                const { data } = await axios.post(
                    `${API_ROOT}/registration/reset-password/`,
                    payload,
                );
                return data;
            } catch (e) {
                logApiError(e, dispatch);
                throw e;
            }
        },
        async resetPassword({ dispatch }, payload) {
            try {
                const { data } = await axios.put(
                    `${API_ROOT}/registration/set-password/`,
                    payload,
                );
                return data;
            } catch (e) {
                logApiError(e, dispatch);
                throw e;
            }
        },

        async refreshUser({ commit, dispatch, getters, rootGetters }) {
            const user = getters.user;
            const userId = user?.id;

            if (!userId) {
                return false;
            }

            try {
                const { data } = await axios.get(
                    `${API_ROOT}/accounts/${userId}/`,
                    rootGetters.axiosConfig,
                );
                if (getters.isImpersonating) {
                    commit('updateImpersonatedUser', data);
                } else {
                    dispatch('saveUser', data);
                }
                return data;
            } catch (e) {
                logApiError(e, dispatch);
                return false;
            }
        },
        saveUser({ commit, state }, data) {
            const user = { ...state.auth.user, ...data };
            const auth = { ...state.auth, user };
            commit('updateAuth', auth);
            commit('marketing/updateMarketingEvents', (user.extra_data || {}).marketing_events);
        },
        async updateUser({ dispatch, getters, rootGetters }, payload) {
            const user = getters.user;
            const userId = user.id;
            delete payload['tasks'];
            try {
                const { data } = await axios.patch(
                    `${API_ROOT}/accounts/${userId}/`,
                    payload,
                    rootGetters.axiosConfig,
                );
                dispatch('saveUser', data);
                return data;
            } catch (e) {
                logApiError(e, dispatch);
                return false;
            }
        },
        /**
         * Dummy action to be used in place of an actual action. Used for testing purposes or to stand in for a real
         * action in v-form steps where we don't actually need to send data to the backend.
         */
        doNothing(_, data) {
            return data;
        },
    },
});

export default store;
